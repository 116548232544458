<template>
    <bread-crumb-panel v-bind:items="[{ label: 'Add a new Account' }]" />
    <div class="row" v-if="accountCreatedSuccess">
        <Message severity="success" :closable="false"
            >Account created, redirecting automatically
            <i style="margin-left: 10px;" class="pi pi-spin pi-spinner"></i>
        </Message>
    </div>
    <div class="row" v-else>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Add a new Account</h5>

                <Message v-if="showError" severity="error" :closable="false">
                    {{
                        errorMessage ||
                            'Issues were found with the supplied data'
                    }}</Message
                >

                <form @submit.prevent="submit">
                    <div class="p-field">
                        <h5>Company name <field-required-notification /></h5>
                        <InputText
                            type="text"
                            class="form-input"
                            :class="{
                                'p-invalid': validated('new_businessname')
                            }"
                            id="new_businessname"
                            name="new_businessname"
                            v-model.trim="form.new_businessname"
                        />
                        <ValidationText
                            v-bind:errors="errors"
                            field="new_businessname"
                        />
                    </div>

                    <div class="p-field">
                        <h5>
                            Role with Company
                            <field-required-notification />
                        </h5>
                        <Dropdown
                            v-model="form.role_id"
                            :options="rolesList"
                            optionValue="id"
                            optionLabel="value"
                            placeholder="Select a Role"
                        />
                        <ValidationText
                            v-bind:errors="errors"
                            field="role_id"
                        />
                    </div>

                    <div class="p-field">
                        <h5>
                            Company Registration No
                            <field-required-notification />
                        </h5>
                        <InputText
                            type="text"
                            class="form-input"
                            :class="{
                                'p-invalid': validated(
                                    'new_companyregistrationnumber'
                                )
                            }"
                            id="new_companyregistrationnumber"
                            name="new_companyregistrationnumber"
                            v-model.trim="form.new_companyregistrationnumber"
                        />
                        <ValidationText
                            v-bind:errors="errors"
                            field="new_companyregistrationnumber"
                        />
                    </div>

                    <div class="p-field">
                        <h5>Email address <field-required-notification /></h5>
                        <InputText
                            type="email"
                            class="form-input"
                            :class="{
                                'p-invalid': validated('emailaddress1')
                            }"
                            id="emailaddress1"
                            name="emailaddress1"
                            autocomplete="emailaddress1"
                            v-model.trim="form.emailaddress1"
                        />
                        <ValidationText
                            v-bind:errors="errors"
                            field="emailaddress1"
                        />
                    </div>

                    <div class="p-field">
                        <h5>Contact No <field-required-notification /></h5>
                        <InputText
                            type="text"
                            class="form-input"
                            :class="{
                                'p-invalid': validated('telephone1')
                            }"
                            id="telephone1"
                            name="telephone1"
                            v-model.trim="form.telephone1"
                        />
                        <ValidationText
                            v-bind:errors="errors"
                            field="telephone1"
                        />
                    </div>

                    <div class="p-field">
                        <h5>Website</h5>
                        <InputText
                            type="text"
                            class="form-input"
                            :class="{
                                'p-invalid': validated('websiteurl')
                            }"
                            id="websiteurl"
                            name="websiteurl"
                            placeholder="https://your-website.co.uk"
                            v-model.trim="form.websiteurl"
                        />
                        <ValidationText
                            v-bind:errors="errors"
                            field="websiteurl"
                        />
                    </div>

                    <postcode-lookup
                        @address-selected="postcodeAddressUpdated"
                        @address-cleared="clearAddressFields"
                    />

                    <div class="p-field">
                        <Button
                            type="submit"
                            click="submit"
                            class="btn btn-primary btn-lg form-input"
                            :disabled="!fieldsCompleted || loading"
                        >
                            <span v-if="loading"
                                ><i class="pi pi-spin pi-spinner"></i
                            ></span>
                            <span v-else>Submit</span>
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import Message from 'primevue/message'
import ValidationText from '@/components/helpers/ValidationText'
import PostcodeLookup from '@/components/helpers/PostcodeLookup'
import { mapActions } from "vuex"

export default {
    name: 'AccountAddView',
    data: () => {
        return {
            form: {
                'new_businessname': '',
                'new_companyregistrationnumber': '',
                'address1_postalcode': '',
                'role_id': '',
                'telephone1': '',
                'emailaddress1': '',
                'websiteurl': '',
                'address1_line1': '',
                'address1_line2': '',
                'address1_line3': '',
                'address1_city': '',
                'address1_county': '',
                'address1_country': '',
            },
            rolesList: [],
            showError: false,
            errorMessage: '',
            errors: [],
            loading: false,
            accountCreatedSuccess: false,
        }
    },
    components: {Message, Button, Dropdown, ValidationText, 'postcode-lookup' : PostcodeLookup},
    computed: {
        fieldsCompleted() {
            if (this.form.new_businessname && this.form.emailaddress1.includes('@') && this.form.emailaddress1.includes('.') && this.form.new_companyregistrationnumber && this.form.role_id && this.form.telephone1 && this.form.address1_postalcode && this.form.address1_line1) {
                return true
            }
            return false
        }
    },
    methods: {
        ...mapActions(["submitNewAccount", "getRoles"]),
        validated(field) {
            const error = this.errors.find((value) => {
                return value.field == field
            })

            return error ? true : false
        },
        postcodeAddressUpdated (addresses) {
            this.form['address1_postalcode'] = addresses.postcode ||''
            this.form['address1_line1'] = addresses.address1 || ''
            this.form['address1_line2'] = addresses.address2 || ''
            this.form['address1_line3'] = addresses.locality || ''
            this.form['address1_city'] = addresses.city || ''
            this.form['address1_county'] = addresses.county || ''
        },
        clearAddressFields () {
            this.form['address1_postalcode'] = ''
            this.form['address1_line1'] = ''
            this.form['address1_line2'] = ''
            this.form['address1_line3'] = ''
            this.form['address1_city'] = ''
            this.form['address1_county'] = ''
        },
        async submit() {
            this.loading = true

            const Account = new FormData()
            Account.append("new_businessname", this.form.new_businessname)
            Account.append("new_companyregistrationnumber", this.form.new_companyregistrationnumber)
            Account.append("role_id", this.form.role_id)
            Account.append("telephone1", this.form.telephone1)
            Account.append("emailaddress1", this.form.emailaddress1)
            Account.append("websiteurl", this.form.websiteurl)
            Account.append("address1_postalcode", this.form.address1_postalcode)
            Account.append("address1_line1", this.form.address1_line1)
            Account.append("address1_line2", this.form.address1_line2)
            Account.append("address1_line3", this.form.address1_line3)
            Account.append("address1_city", this.form.address1_city)
            Account.append("address1_county", this.form.address1_county)
            //Account.append("address1_country", this.form.address1_country)

            try {
                this.showError = false
                this.errors = []
                await this.submitNewAccount(Account)
                this.accountCreatedSuccess = true
                //Clear Profile Roles
                this.$store.commit('clearContactData')

                setTimeout(() => {
                    this.$router.push('/user')
                }, 2000)

                //reload Profile Roles
                await this.getRoles()

            } catch (error) {
                if (error.response.status == 422) {
                    this.errors = error.response.data.errors
                }
                this.showError = true
            } finally {
                this.loading = false
            }
        },
    },
    async created() {
        const values = await this.$store.dispatch('retrieveRoleNames')
        this.rolesList = values.filter(data => {
            return data.value == 'Owner' || data.value == 'Director'
        })

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-input {
    width: 100%;
}
.p-field {
    display: block;
    margin-top: 20px;
}
::v-deep(.p-dropdown) {
    .p-hidden-accessible {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .p-hidden-accessible input,
    .p-hidden-accessible select {
        transform: scale(0);
    }
}
</style>
